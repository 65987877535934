import React from "react"
import { Box, Button, Stack } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  Container,
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  IntroText,
  PrimaryPagesHero,
  SectionHeading,
  TwoCols,
  VideoPlayer,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

import {
  DonorStories,
  GivingOpportunities,
  GivingTeam,
  Seo,
} from "../../components/elements"

const Giving = ({ data, pageContext }) => {
  const page = data.datoCmsCancerGiving
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <Seo
        title={page.seo.title}
        description={page.seo.description}
        image={page.seo.image && page.seo.image.url}
      />

      <PrimaryPagesHero page={page}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={page.title}
        />
      </PrimaryPagesHero>

      <Main>
        {/* IntroContent */}
        <Section id="intro">
          <Container>
          {page?.video?.url &&
            <Box width={["full", "full", 3 / 4]} mx="auto" mb={12}>
              <VideoPlayer url={page.video.url} />
            </Box>
          }
            <Box width="full">
              <IntroText>{page.introContent}</IntroText>
            </Box>
            <Stack direction={["column", "column", "row"]} spacing={12}>
              <Box width={["full"]} alignSelf="center">
                <TwoCols
                  content={`${page.contentNode.childMarkdownRemark.html}`}
                />
              </Box>
            </Stack>

            <Box textAlign={"center"} mt={12}>
              <Button
                as="a"
                variant="solid"
                href="https://give.wvu.edu/campaign/wvu-cancer-institute/c430764"
                textDecoration={"none"}
                size="lg"
                bg="secondary"
              >
                Donate Now
              </Button>
            </Box>
          </Container>
        </Section>

        {/* Opportunities */}
        <Box id="opportunities">
          <Box width="full">
            <SectionHeading textAlign="center" mb={3} id="ways">
              Ways You Can Give
            </SectionHeading>

            <GivingOpportunities />
          </Box>
        </Box>

        {/* Team */}
        <Section id="team" bg="lightgray">
          <Container>
            <SectionHeading textAlign="center" mb={3}>
              Our Team
            </SectionHeading>

            <GivingTeam page={page} />
          </Container>
        </Section>

        <Section id="stories" bg="white">
          <Container>
            <SectionHeading textAlign="center" mb={3}>
              Donor Stories
            </SectionHeading>

            <DonorStories />
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default Giving

export const query = graphql`
  {
    datoCmsCancerGiving {
      title
      slug
      contentNode {
        childMarkdownRemark {
          html
        }
        id
      }
      introContent
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { ar: "1600:720", fit: "crop", crop: "focalpoint" }
        )
      }
      team {
        id
        email
        phoneNumber
        name
        title
        photo {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { fit: "crop", crop: "focalpoint" }
          )
        }
      }
      video {
        url
      }
      seo {
        description
        title
        image {
          url
        }
      }
    }
  }
`
